.footer {
  position: fixed;
  border: none;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50px;
  font-size: 100%;
  background-color: rgba(0, 128, 0, 0.7);
  color: rgb(255, 255, 255);
  text-align: right;
  padding: 10px;
}
.footer-logout {
  position: fixed;
  border: none;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50px;
  font-size: 100%;
  background-color: rgba(255, 0, 0, 0.5);
  color: rgb(255, 255, 255, 0.9);
  text-align: center;
  padding: 10px;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.container div {
  margin-top: auto;
  margin-bottom: auto;
}
.margin_top {
  margin-top: 10px !important;
}
.margin_bottom {
  margin-bottom: 10px;
}
.margin_left {
  margin-left: 10px;
}
.margin_right {
  margin-right: 10px;
}

html,
body,
#root {
  height: 100%;
  background-image: url(./utils/back-.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  min-height: 400px;
  margin-bottom: 270px;
  clear: both;
}
@media screen and (orientation: portrait) {
  .logo img{
    display: none;
  }
  .bg-mine {
    /* Add shadows to create the "card" effect */
    background-color: rgba(255, 255, 255, 0.7);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    width: 95vw;
    margin-left: auto;
    margin-right: auto;
    padding: 2%;
    border-radius: 20px;
  }

  /* On mouse-over, add a deeper shadow */
  .bg-mine:hover {
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
}

/* For landscape, we want the tool bar stick on the left */

@media screen and (orientation: landscape) {
  .logo {
    height: 100vh;
    width: 13.5%;
    left: 0;
    margin-left: 2%;
    margin-right: 2%;
    background-color: rgba(255, 255, 255, 0.7);
    position: fixed;
    align-items: center;
  }

  .bg-mine {
    /* Add shadows to create the "card" effect */
    background-color: rgba(255, 255, 255, 0.7);
    width: 65vw;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    /* inner */
    border-radius: 20px;
    margin-left: auto;
    margin-right: auto;
    padding: 2%;
  }

  /* On mouse-over, add a deeper shadow */
  .bg-mine:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
}

/* button:focus {
  background-color: #007bff;
  color: white;
} */

hr.style-one {
  border: 0;
  height: 1px;
  background: #333;
  background-image: linear-gradient(to right, #ccc, #333, #ccc);
}

.toggle-control {
  display: block;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.toggle-control input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.toggle-control input:checked ~ .control {
  background-color: rgb(93, 172, 132);
}
.toggle-control input:checked ~ .control:after {
  left: 15px;
}
.toggle-control .control {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 30px;
  border-radius: 25px;
  background-color: rgb(216, 216, 216);
  transition: background-color 0.1s ease-in;
}
.toggle-control .control:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 5px;
  width: 10px;
  height: 10px;
  border-radius: 25px;
  background: white;
  transition: left 0.1s ease-in;
}

.btn-smol {
  height: 50px;
  line-height: 50px;
  width: 50px;
  font-size: 0.85em;
  border-radius: 50%;
  border: none;
  background-color: rgba(0, 0, 0);
  color: white;
  text-align: center;
  cursor: pointer;
}

.btn-media:focus {
  background-color: #007bff;
  color: white;
}

.btn-round {
  height: 50px;
  width: 200px;
  line-height: 50px;
  font-size: 0.85em;
  border-radius: 50px;
  border: none;
  background-color: rgba(0, 0, 0);
  color: white;
  text-align: center;
  cursor: pointer;
}

.btn-fab {
  position: fixed;
  padding: 10px;
  height: 50px;
  width: 200px;
  line-height: 50px;
  font-size: 0.85em;
  border-radius: 50px;
  border: none;
  bottom: 20px;
  right: 20px;
  background-color: rgba(255, 0, 0, 0.7);
  color: #fff;
  border-color: transparent;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  transition: all 0.2s ease-in-out;
}

.btn-fab:hover {
  transform: scale(1.02);
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.course-title {
  /* Add shadows to create the "card" effect */
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 100%;
  border: none;

  /* inner */
  margin-left: auto;
  margin-right: auto;
  padding: 2%;
}

/* On mouse-over, add a deeper shadow */
.course-title:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}


